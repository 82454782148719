import { merge } from 'lodash';

import { siteReducers } from '~/state/reducers';

import { entities } from './entities/reducers';
import { progress } from './progress/reducers';
import { scenes } from './scenes/reducers';

const staticReducer = (state = {}) => state;
const invoicesReducer = (state = []) => state;
const chargesReducer = (state = []) => state;

export const reduceInitialState = (initialProps = {} as any) =>
  merge({}, initialProps.portalData, initialProps.reduxData);

const rootReducer = {
  ...siteReducers,
  entities,
  invoices: invoicesReducer,
  charges: chargesReducer,
  progress,
  scenes,
  static: staticReducer,
};

export default rootReducer;
